/* eslint-disable react/no-danger */
import createDOMPurify from 'dompurify';
import he from 'he';

interface Props {
  htmlText: string;
  isInheritColor?: boolean;
}

export const RenderHtmlText: React.FC<Props> = ({ htmlText, isInheritColor = false }: Props): JSX.Element => {
  const DOMPurify = createDOMPurify(window);
  const getDecodedHtml = () => {
    if (htmlText?.includes('&lt;')) {
      const decodedHTML = he.decode(htmlText ?? '');

      DOMPurify.addHook('afterSanitizeAttributes', (node) => {
        if (node.tagName && node.tagName.toLowerCase() === 'a') {
          node.setAttribute('target', '_blank');
          node.setAttribute('rel', 'noopener noreferrer');
        }
      });

      return DOMPurify.sanitize(decodedHTML, { ADD_ATTR: ['target', 'rel'] });
    }
    return DOMPurify.sanitize(htmlText?.replace(/^\s+|\s+$/g, ''), { ADD_ATTR: ['target', 'rel'] });
  };

  return (
    <div
      className={isInheritColor ? 'html-text-inherit-color' : 'html-text-wrapper'}
      dangerouslySetInnerHTML={{ __html: getDecodedHtml() }}
    />
  );
};
